import * as React from "react";
import { Box } from "theme-ui";
import "@reach/menu-button/styles.css";

const Hero = ({ heroImage, title, sx = {} }) => {
  // TODO: Figure out how to properly position the image when `background-attachment: fixed;`
  return (
    <Box
      sx={{
        backgroundColor: "#f9d29d",
        backgroundImage: heroImage
          ? `url(${heroImage})`
          : "linear-gradient(315deg, #B03F36 0%, #861657 42%)",
        height: "360px",
        width: "auto",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        ...sx,
      }}
    >
      {title}
    </Box>
  );
};

export { Hero };
