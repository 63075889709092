/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */
import React from "react";
import { Helmet } from "react-helmet";
import { graphql, useStaticQuery } from "gatsby";
import favicon from "../images/gatsby-icon-alt.png";
import { useLocation } from "@reach/router";

const SEO = ({ description = "", lang = "en", meta = [], title }) => {
  const { pathname } = useLocation();
  const { site } = useStaticQuery(
    graphql`
      query SEO {
        site {
          siteMetadata {
            defaultTitle: title
            titleTemplate
            defaultDescription: description
            siteUrl
          }
        }
      }
    `
  );

  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    siteUrl,
  } = site.siteMetadata;

  const seo = {
    title: title || defaultTitle,
    titleTemplate,
    description: description || defaultDescription,
    url: `${siteUrl}${pathname}`,
  };

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={seo.title}
      titleTemplate={title ? `${title} | ${defaultTitle}` : seo.titleTemplate}
      meta={[
        {
          name: "robots",
          content: "noindex nofollow",
        },
      ].concat(meta)}
    >
      <link rel="icon" href={favicon} />
    </Helmet>
  );
};

export default SEO;
