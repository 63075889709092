import { Flex, Heading, Image } from "theme-ui";
import * as React from "react";
import { logo } from "../../assets";

const headerLinks = [
  {
    label: "Home",
    href: "/",
  },
  {
    label: "Book Appointment",
    href: "/appointments",
  },
  // {
  //   label: "Classes & Events",
  //   href: "/events",
  // },
  {
    label: "Store",
    href: "/store",
  },
  {
    label: "Blog",
    href: "/blog",
  },
  {
    label: "Contact Us",
    href: "/contact-us",
  },
];

export const NavBar = () => {
  return (
    <Flex
      width="100%"
      backgroundColor="backgroundPeach"
      sx={{
        paddingY: "1rem",
        flexDirection: ["row-reverse", "column"],
        justifyContent: ["space-between"],
        alignItems: "center",
      }}
    >
      <Flex sx={{ alignItems: "center" }}>
        <Image
          src={logo}
          alt="Eternal Light Spiritual Center logo"
          sx={{ width: ["64px", "72px", "96px"], marginRight: "1rem" }}
        />
        <Heading
          sx={{
            display: ["none", "block"],
            fontWeight: 500,
            fontSize: [null, 4, 5],
          }}
        >
          Eternal Light Spiritual Center
        </Heading>
      </Flex>
    </Flex>
  );
};
