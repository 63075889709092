import { Box } from "theme-ui";
import * as React from "react";

export const ElcSection = ({
  id = undefined,
  as = "section",
  sx: {
    backgroundColor = "white",
    backgroundImage = undefined,
    ...otherSxProps
  } = {},
  children,
}) => (
  <Box
    {...(id && { id })}
    as={as}
    sx={{
      width: "100%",
      backgroundColor,
      backgroundImage,
    }}
  >
    <Box
      sx={{
        maxWidth: "1140px",
        marginX: "auto",
        padding: "4rem",
        textAlign: "center",
        ...otherSxProps,
      }}
    >
      {children}
    </Box>
  </Box>
);
