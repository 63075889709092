import * as React from "react";
import SEO from "../SEO";
import { Box, Flex, Heading } from "theme-ui";
import { Hero } from "../hero";
import { Footer } from "../footer";
import { NavBar } from "../nav-bar";

import "normalize.css";

/**
 * A wrapper component which adds Hero and Footer section around children
 * @param children
 * @param title
 * @param titleColor
 * @param heroImage
 * @param showHero
 * @param heroImageSx
 * @returns {JSX.Element}
 */
export const PageLayout = ({
  children,
  title,
  titleColor,
  heroImage,
  showHero = true,
  heroImageSx = { heroBackgroundPosition: "center" },
}) => {
  return (
    <>
      <SEO title={title} />
      <Box sx={{ margin: "auto" }}>
        <main>
          <NavBar />
          {showHero && (
            <Hero
              title={
                <Flex
                  sx={{
                    height: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {title && (
                    <Heading
                      sx={{ color: titleColor ?? "white", fontSize: "2.5rem" }}
                    >
                      {title}
                    </Heading>
                  )}
                </Flex>
              }
              titleColor={titleColor}
              heroImage={heroImage ?? ""}
              sx={heroImageSx}
            />
          )}
          {children}
        </main>
        <Footer />
      </Box>
    </>
  );
};
