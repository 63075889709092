import { ElcSection } from "../layout-components";
import { Box, Flex, Heading, Link, Text } from "theme-ui";
import { FaEnvelope, FaFacebookF, FaInstagram } from "react-icons/fa";
import * as React from "react";
import GatsbyLink from "gatsby-link";

export const Footer = () => {
  return (
    <ElcSection
      as="footer"
      sx={{
        backgroundColor: "footerGray",
        color: "white",
        display: "flex",
        flexDirection: ["column", "row"],
      }}
    >
      <Box
        sx={{
          width: ["100%", "50%", "30%"],
          textAlign: "left",
          marginBottom: ["1rem", 0],
        }}
      >
        <Heading sx={{ marginBottom: "1rem" }}>Eternal Light Center</Heading>
        <Flex>
          {/**
           * insta, youtube, facebook
           * TODO: Get links for social media pages
           */}
          <Link
            href="https://www.instagram.com/eternallightcenter/"
            target="_blank"
            rel="noopener"
            sx={{ color: "white", marginRight: "1rem" }}
          >
            <FaInstagram size="1.5rem" />
          </Link>
          <Link
            href="https://www.facebook.com/eternalightcenter"
            target="_blank"
            rel="noopener"
            sx={{ color: "white", marginRight: "1rem" }}
          >
            <FaFacebookF size="1.5rem" />
          </Link>
          <Link as={GatsbyLink} to="/contact-us" sx={{ color: "white" }}>
            <FaEnvelope size="1.5rem" />
          </Link>
        </Flex>
      </Box>
      <Box sx={{ width: ["100%", "50%", "70%"] }}>
        <Text
          sx={{
            fontSize: "0.8125rem",
            fontStyle: "italic",
            textAlign: "left",
            marginBottom: "0.75rem",
            color: "white",
          }}
        >
          Medical Advice Disclaimer: <br />
          Our staff of talented Healers are not Medical Professionals and do not
          claim to be so. The information they provide on physical conditions is
          not intended to substitute professional medical advice, diagnosis, or
          treatment. Therefore, we encourage you to follow up with trained
          Medical Professionals in matters regarding your Physical Health.
        </Text>
      </Box>
    </ElcSection>
  );
};
