import * as React from "react";
import { Box, Button, Heading, Spinner, Text } from "theme-ui";
import { mainPage } from "../../assets";
import { PageLayout } from "../../components/page-layout";
import { ElcSection } from "../../components/layout-components";
import { navigate } from "@reach/router";
import delay from "lodash/delay";

const WeMoved = () => {
  const newSiteUrl = "https://eternallightspiritual.center/";

  React.useEffect(() => {
    delay(() => navigate(newSiteUrl, { replace: true }), 5000);
  }, []);

  return (
    <PageLayout heroImage={mainPage}>
      <ElcSection>
        <Heading
          as="h1"
          sx={{
            marginBottom: "1rem",
            color: "darkGold",
            fontWeight: "bolder",
          }}
        >
          We&apos;ve moved! Redirecting you now...
        </Heading>

        <Spinner color="darkGold" />

        <Box sx={{ marginBottom: "1.5rem" }}>
          <Text
            as="p"
            sx={{
              fontStyle: "italic",
              fontSize: "1.25rem",
              marginBottom: "0.5rem",
            }}
          >
            We&apos;ve moved to our new home at{" "}
            <a href={newSiteUrl}>Eternal Light Spiritual Center</a>!
          </Text>
          <Text>
            Click below if you are not automatically redirected to the new site.
          </Text>
        </Box>

        <Button variant="main" as="a" href={newSiteUrl}>
          Go to the new site
        </Button>
      </ElcSection>
    </PageLayout>
  );
};

export default WeMoved;
